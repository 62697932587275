import { useGetTranslatedCategory } from "hooks/useGetTranslatedCategory";
import { useEffect, useMemo } from "react";
  // [STARCAR-1921] Hide the Oneway Checkbox if station is Preset (but no return station is preset - KLM!) and reset the oneWay checkbox value to false, when switching category to make sure the layout doesnt break;

export const useHideOneWayForNotSupportedStationAndCategory = ({formMethods, stations, presetReturnStation }) => {
    const getTranslatedCategory = useGetTranslatedCategory()
    const {watch, setValue, clearErrors} = formMethods
    let hideOneWayForCategoryIfStationDoesntSupportIt;
    const [category, isOneWay, selectedStationId] = watch(['category', 'oneWay','station', 'returnStation']);
    const selectedStation = useMemo(() => stations.find(station => station?.id === selectedStationId), [selectedStationId, stations]);
    if(selectedStationId && selectedStation && !presetReturnStation) {
      if(getTranslatedCategory('pkw') === category && !selectedStation?.oneWayCar) {
        hideOneWayForCategoryIfStationDoesntSupportIt = true;
        if(isOneWay) {
          setValue('oneWay', false)
        }
      }
      if(getTranslatedCategory('lkw') === category && !selectedStation?.oneWayTruck) {
        hideOneWayForCategoryIfStationDoesntSupportIt = true;
        if(isOneWay) {
          setValue('oneWay', false)
        }
      }
    }
    useEffect(() => {
        if(!hideOneWayForCategoryIfStationDoesntSupportIt) {
            clearErrors('stationTextField')
        }
    }, [hideOneWayForCategoryIfStationDoesntSupportIt])
    return hideOneWayForCategoryIfStationDoesntSupportIt
  }